import { useState } from "react";
import Navbar from "../components/navbar";
import SidebarMenu from "../components/sidebarMenu";

const AppStructure = ({children}) => {
    const [menuActive,setMenuActive] = useState(false);

    function toggleMenu(e){
        e.preventDefault();

        const active = menuActive;
        setMenuActive(!active);
    }

    return(
        <div className="full-page">
            <SidebarMenu menuActive={menuActive} toggleMenu={toggleMenu} />
            
            <div className="structure">
                <Navbar menuActive={menuActive} toggleMenu={toggleMenu} />
                <section className="app-body">
                    <div className="container">
                        {children}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AppStructure;