const useMenuList = () => {
    return [
        {
            title:"Votaciones",
            icon:"how_to_vote",
            url: "/votes",
            submenu:[
                {
                    title: "Publicaciones",
                    icon: "library_books",
                    url: "/blog/all-posts"
                },
                {
                    title:"Nueva Publicación",
                    icon: "post_add",
                    url: "/blog/new-post"
                },
                {
                    title: "Categorías",
                    icon: "category",
                    url: "/blog/categories"
                }
            ],
            users: [
                "*",// everyone
            ]
        },
        /*{
            title: "CRM",
            icon: "contact_mail",
            submenu:[
                {
                    title: "Mensajes",
                    icon: "inbox",
                    url: "/crm/inbox"
                },
            ],
            users: [
                "*", //everyone
            ]
        }*/
    ];
};

export default useMenuList;