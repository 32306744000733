//import logo from "../assets/img/isotipo.svg";
import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/img/logotipo_immar.png";
import { useAuth } from "../functions/authContext";
import useMenuList from "../functions/useMenuList";

const SidebarMenu = ({menuActive,toggleMenu}) => {
    const [optionSelected,setOptionsSelected] = useState(0);
    const [submenuActive,setSubmenuActive] = useState(false);
    const [ error, setError ] = useState("");
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();
    const menuList = useMenuList();

    function handleMenuItemClick(e){
        const index = e.currentTarget.id;
        setOptionsSelected(index);
        showSubmenu();
    }

    function showSubmenu(){
        setSubmenuActive(true);
    }

    function hideSubmenu(){
        setSubmenuActive(false);
    }

    async function handleLogout(){
        setError("");

        try{
            await logout();
            navigate("/login");
        }catch(err){
            setError("Ocurrió un error al cerrar la sesión");
            console.log(err);
        }
    }

    return(
        <div className={ menuActive ? `sidebar-menu active` : `sidebar-menu`}>
            <div className="conceptual-menu">
                <div className="menu-options">
                    <ul>
                        <li className="logo">
                            <Link className="navbar-item" to="/">
                                <img src={logo} alt="IMMAR online" />
                            </Link>
                        </li>
                        {menuList.map((item,index) => {
                            const found = item.users.includes(currentUser.uid);
                            const forAll = item.users.includes("*");
                            
                            if(found || forAll)
                                return(
                                    <MenuItem
                                        key={item.title}
                                        title={item.title}
                                        icon={item.icon}
                                        index={index}
                                        to={item.url}
                                        onClick={handleMenuItemClick}
                                    />
                                );
                        })}
                    </ul>
                </div>
                <div className="menu-account">
                    <button className="is-hidden-tablet" title="Ocultar Menú" onClick={toggleMenu}><span className="material-symbols-rounded">arrow_back</span></button>
                    <button title="Cerrar Sesión" onClick={handleLogout}><span className="material-symbols-rounded">logout</span></button>
                </div>
            </div>
            <div className={ submenuActive ? `contextual-menu active` : `contextual-menu`}>
                <ul>
                    <li className="submenu-title">
                        <h2>{menuList[optionSelected].title}</h2>
                        <button onClick={hideSubmenu}><span className="material-symbols-rounded">close</span></button>
                    </li>

                    {menuList[optionSelected].submenu.map((item) => {
                        return(
                            <SubmenuItem
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                url={item.url}
                                hideSubmenu={hideSubmenu}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SidebarMenu;

const MenuItem = ({title,icon,index,to,onClick}) => {
    const navigate = useNavigate();

    function internalOnClick(e){
        e.preventDefault();

        console.log(to);
        if(to) navigate(to);
        else onClick(e);
    }

    return(
        <li>
            <button title={title} id={index} onClick={internalOnClick}>
                <span className="material-symbols-rounded">{icon}</span>
            </button>
        </li>
    );
};

const SubmenuItem = ({title,icon,url,hideSubmenu}) => {
    return(
        <li className="submenu-item">
            <NavLink to={url} onClick={hideSubmenu}>
                <p>{title}</p>
                <span className="material-symbols-rounded">{icon}</span>
            </NavLink>
        </li>
    );
};