import { useRef, useState } from "react";
import { useAuth } from "../../functions/authContext";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/img/logotipo_immar.png";

const Login = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const { login } = useAuth();
    const navigate = useNavigate();

    async function handleSubmit(e){
        e.preventDefault();

        try{
            setError("");
            setLoading(true);

            await login(emailRef.current.value,passwordRef.current.value);
            navigate("/");
        }catch(err){
            setError("Algo salió mal con tus datos, ¡Intenta de nuevo o recupera tu contraseña!");
            console.log(err);
        }

        setLoading(false);
    }

    return(
        <div className="access-page">
            <div className="box">
                <img src={logo} alt="IMMAR online" />

                <div className="block">
                    { error && <div class="notification is-danger">{error}</div>}
                </div>

                <div className="block">
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <input className="input" ref={emailRef} type="email" placeholder="Correo Electrónico" />
                        </div>
                        <div className="field">
                            <input className="input" ref={passwordRef} type="password" placeholder="Contraseña" />
                        </div>
                        <div className="is-centered">
                            <button className="button is-link" disabled={loading} type="submit">Entrar</button>
                        </div>
                    </form>
                </div>
                
                <div className="block" style={{textAlign:"center"}}>
                    <Link to="/forgot-password">Olvidé mi contraseña</Link>
                    <br />o<br />
                    <Link to="/register">Crear una nueva cuenta</Link>
                </div>
            </div>
        </div>
    );
};

export default Login;