import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../functions/authContext";

const PrivateRouter = ({ children }) => {
    const {currentUser} = useAuth();
    const location = useLocation();

    if(!currentUser)
        return <Navigate to="/login" state={{from: location}} replace />

    return children;
};

export default PrivateRouter;