import { useEffect, useState } from "react";
import useAPI from "../../connection/useAPI";
import AppStructure from "../../structures/appStructure";

const Votes = () => {
    const { votes } = useAPI();
    const [ campaignTitle, setCampaignTitle ] = useState('Cargando Datos...');
    const [ candidatesList, setCandidatesList ] = useState([]);
    const [ votedCandidates, setVotedCandidates ] = useState([]);
    const [ hasVoted, setHasVoted ] = useState(false);

    useEffect(() => {
        getServerData();
    }, []);

    function getServerData(){
        const promises = [
            votes.getCandidates(1),
            votes.hasVoted(1)
        ];
        Promise.all(promises).then((data) => {
            let textAux = '';

            if(data[1].result === true){
                setHasVoted(true);
                textAux = ' - Ya se ha registrado tu voto.'
            }else
                setHasVoted(false);

            const campaign = data[0].result;
            const startDate = new Date(campaign.startDate);
            const endDate = new Date(campaign.endDate);
            const today = new Date();
            
            const months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];

            if(startDate.getTime() > today.getTime()){
                textAux = ` - Las elecciones podrán realizarse a partir de las ${startDate.getHours()}:${startDate.getMinutes()} hrs. del ${startDate.getDate()} de ${months[startDate.getMonth()]} del ${startDate.getFullYear()}`;
                setHasVoted(true);
            }
            
            if(endDate.getTime() < today.getTime()){
                textAux = ` - Las elecciones han finalizado a partir de las ${startDate.getHours()}:${startDate.getMinutes()} hrs. del ${startDate.getDate()} de ${months[startDate.getMonth()]} del ${startDate.getFullYear()}`;
                setHasVoted(true);
            }

            setCampaignTitle(`${campaign.campaignDescription}${textAux}`);
            setCandidatesList(campaign.candidates);
        });
    }

    function sendVote(){
        if(votedCandidates.length < 8)
            alert("Es necesario votar por 8 de los candidatos");
        else{
            votes.send(1,JSON.stringify(votedCandidates)).then((response) => {
                if(response.code === 200){
                    getServerData();
                }
            });
        }
    }

    return(
        <AppStructure>
            <div className="block">
                <h1 className="title">Votaciones</h1>
                <h2>{campaignTitle}</h2>
            </div>

            <div className="block">
                <div className="columns is-multiline">
                    {candidatesList.map((candidate) => {
                        return(
                            <div key={candidate.id} className="column is-one-quarter-desktop">
                                {!hasVoted ? 
                                    <Candidate
                                        id={candidate.id}
                                        name={candidate.name}
                                        photo={candidate.photo}
                                        votedCandidates={votedCandidates}
                                        setVotedCandidates={setVotedCandidates}
                                    />:
                                    <CandidateVotes
                                        name={candidate.name}
                                        photo={candidate.photo}
                                        votes={candidate.votes}
                                    />
                                }
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="block">
                &nbsp;<br />&nbsp;
            </div>

            {!hasVoted && <div style={{position:"fixed",left:0,bottom:0,width:"100%"}}>
                <div className="card">
                    <div className="card-content">
                        <div className="content" style={{textAlign:"center"}}>
                            <button className="button is-info" onClick={sendVote}>Enviar Voto</button>
                        </div>
                    </div>
                </div>
            </div>}
        </AppStructure>
    );
};

export default Votes;

const Candidate = ({id,name,photo,votedCandidates,setVotedCandidates}) => {
    const [ voted, setVoted ] = useState(false);

    function handleVote(e){
        e.preventDefault();

        const selectedCandidates = [...votedCandidates];

        if(voted){
            const index = selectedCandidates.findIndex(item => item === id);
            
            selectedCandidates.splice(index,1);
            setVotedCandidates(selectedCandidates);
            setVoted(!voted);
        }else{
            if(votedCandidates.length < 8){

                selectedCandidates.push(id);
                setVotedCandidates(selectedCandidates);
                setVoted(!voted);
            }else
                alert("No se pueden seleccionar más de 8 candidatos");
        }
    }

    return(
        <div className="box">
            <article className="media">
                <div className="media-left">
                    <figure className="image is-64x64">
                        <img className="is-rounded" src={`${photo}?v1`} alt={name} />
                    </figure>
                </div>
                <div className="media-content">
                    <div className="content">
                        <p><strong>{name}</strong></p>
                    </div>
                    <nav className="level">
                        { voted ?
                            (<a href="#" onClick={handleVote} className="card-footer-item button is-primary">Quitar Voto &nbsp; <i className="material-symbols-rounded">thumb_down</i></a>) :
                            (<a href="#" onClick={handleVote} className="card-footer-item button is-link">Dar Voto &nbsp; <i className="material-symbols-rounded">thumb_up</i></a>)
                        }
                    </nav>
                </div>
            </article>
        </div>
    );
};

const CandidateVotes = ({name,photo,votes}) => {
    return(
        <div className="box">
            <article className="media">
                <div className="media-left">
                    <figure className="image is-64x64">
                        <img className="is-rounded" src={`${photo}?v1`} alt={name} />
                    </figure>
                </div>
                <div className="media-content">
                    <div className="content">
                        <p><strong>{name}</strong></p>
                    </div>
                    <nav className="level">
                        Votos Acumulados: <strong>{votes}</strong>
                    </nav>
                </div>
            </article>
        </div>
    );
};