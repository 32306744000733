import AppStructure from "../../structures/appStructure";
import { Link } from "react-router-dom";

const Dashboard = () => {
    return(
        <AppStructure>
            <h1 className="title">Templo Metodista Emmanuel</h1>

            <div className="box">
                <p>A través de la plataforma se pueden hacer las elecciones para nuestros administradores 2023</p>
            </div>

            <h3 className="title">Instrucciones</h3>
            <div className="box">
                <p><strong>Paso 1:</strong> Ve al menú de la izquierda y selecciona el botón de Votaciones <span className="icon"><i className="material-symbols-rounded">how_to_vote</i></span></p>
                <p><small>En caso de estar en el celular, dirígete a la barra de navegación de arriba y presiona el botón <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="3" x2="9" y2="21"></line></svg></span> para abrir el menú</small></p>
            </div>
            <div className="box">
                <p><strong>Paso 2:</strong> Te permitirá realizar la votación únicamente el día domingo 30 de Octubre del 2022 de 11:30 am. a 01:30 pm.</p>
            </div>
            <div className="box">
                <p><strong>Paso 3:</strong> Si estás en el horario de las votaciones, podrás elegir únicamente 8 de los 16 candidatos para administradores presionando el botón Dar Voto <span className="icon"><i className="material-symbols-rounded">thumb_up</i></span></p>
            </div>
            <div className="box">
                <p><strong>Paso 4:</strong> En caso de cambiar de opinión, podrás cambiar tu voto presionando el botón Quitar Voto <span className="icon"><i className="material-symbols-rounded">thumb_down</i></span></p>
            </div>
            <div className="box">
                <p><strong>Paso 5:</strong> Cuando estés seguro de haber seleccionado a tus 8 candidatos, presiona el botón "Enviar Voto" para terminar tu votación.</p>
            </div>
            <div className="box">
                <p><strong>Paso 6:</strong> Podrás ver los resultados hasta el momento cada vez que ingreses al apartado de votaciones.</p>
            </div>
            <div className="box">
                <p><Link to="/votes" className="button is-primary">Ir a Votaciones</Link></p>
            </div>
        </AppStructure>
    );
};

export default Dashboard;