import { useAuth } from "../functions/authContext";

const useAPI = () => {
    const { currentUser } = useAuth();
    
    const apps = {
        server: "https://elecciones.temploemmanuel.page/api/?project=tme",
        //server: "http://localhost/tmeAPI/?project=tme",
        //user: currentUser.uid,
        votes: {
            app:"&app=votes",
            getCandidates: async function(campaignId){
                return await fetch(`${apps.server}${apps.votes.app}&function=getCandidates`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `campaignId=${campaignId}`
                }).then(reponse => reponse.json());
            },
            send: async function(campaignId,votes){
                return await fetch(`${apps.server}${apps.votes.app}&function=send`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `campaignId=${campaignId}&uid=${currentUser.uid}&votes=${votes}`
                }).then(reponse => reponse.json());
            },
            hasVoted: async function(campaignId){
                return await fetch(`${apps.server}${apps.votes.app}&function=hasVoted`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `campaignId=${campaignId}&uid=${currentUser.uid}`
                }).then(reponse => reponse.json());
            },
            registerUser: async function(uid,email,name){
                return await fetch(`${apps.server}${apps.votes.app}&function=registerUser`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&email=${email}&name=${name}`
                }).then(reponse => reponse.json());
            },
        }
    };

    return apps;
}

export default useAPI;