import "../node_modules/bulma/css/bulma.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./functions/authContext";
import Login from "./pages/access/login";
import PrivateRouter from "./components/privateRouter";
import Dashboard from "./pages/general/dashboard";
import Votes from "./pages/votes/votes";
import ForgotPassword from "./pages/access/forgotPassword";
import Register from "./pages/access/register";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRouter><Dashboard /></PrivateRouter>} />

          {/* Access */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />

          {/* Votes */}
          <Route path="/votes" element={<PrivateRouter><Votes /></PrivateRouter>} />

          {/* Blog 
          <Route path="/blog/new-post" element={<PrivateRouter><NewPost /></PrivateRouter>} />
          <Route path="/blog/categories" element={<PrivateRouter><Categories /></PrivateRouter>} />
          <Route path="/blog/all-posts" element={<PrivateRouter><AllPosts /></PrivateRouter>} />*/}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;