import logo from "../assets/img/logotipo_immar.png";
import { Link } from "react-router-dom";

const Navbar = ({menuActive,toggleMenu}) => {
    return(
        <nav className={ menuActive ? `navbar mobile-navbar is-hidden-desktop is-hidden-tablet is-hidden` : `navbar mobile-navbar is-hidden-desktop is-hidden-tablet` } aria-label="main navigation">
            {/*<!-- Brand -->*/}
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <object data={logo} width="32"> </object>
                </Link>

                {/*<!-- Sidebar mode toggler icon -->*/}
                <a onClick={toggleMenu} id="sidebar-mode" className="navbar-item is-icon is-sidebar-toggler" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="3" x2="9" y2="21"></line></svg>
                </a>
            </div>
        </nav>
    );
};

export default Navbar;